import React from 'react';
import Alert from 'react-bootstrap/Alert';
import DetectRTC from 'detectrtc';
import ResultIcon  from './resultIcon';

class DetectSystem extends React.Component {
    constructor(props) {
      super(props);
      this.state = {OS: "", browser: "", OSvalidation: "info", browserValidation: "info"};
    }
    
    componentDidMount() {
        const system = DetectRTC;

        var current = this;

        let osValidation = system.osName ? 'success' : 'danger';
        let browser = (system.browser.name && system.browser.version) ? system.browser.name + ' ' + system.browser.version : 'Undefined';
        let browserVersion = system.browser.version;
        let browserValidation = (system.browser.name && browserVersion) ? 'success' : 'danger';

        current.setState({
            OS: system.osName, browser: browser, OSvalidation: osValidation, browserValidation: browserValidation
        });

        let min = 0;
        switch (system.browser.name) {
            case 'Chrome':
                min = 97;
                break;
            case 'Safari':
                min = 14;
                break;
            case 'Firefox':
                min = 94;
                break;
                case 'Opera':
                min = 75;
                break;
            case 'Edge':
                min = 95;
                break;
                default:
                    min = 0;
        }

        if (browserVersion < min) {
            current.setState({
                browserValidation: 'danger'
            });
        }

        
    }
  
    componentWillUnmount() {
        this.setState({
            OS: "Undefined", browser: "Undefined", OSvalidation: "danger", browserValidation: "danger"
        });
    }
  
    render() {
        return <div>
            <div className="p-3 border-bottom text-start">
                <span className="d-flex justify-content-between">
                    <h6 className="fw-bold mb-3">Operating System</h6>
                    <a className="text-dark" href="/operating-system">Troubleshoot</a>
                </span>
                <Alert variant={this.state.OSvalidation}>
                <ResultIcon result={this.state.OSvalidation} /><small>{this.state.OS}</small>
                </Alert>
            </div>
            <div className="p-3 text-start">
                <span className="d-flex justify-content-between">
                    <h6 className="fw-bold mb-3">Browser</h6>
                    <a className="text-dark" href="/internet-browser">Troubleshoot</a>
                </span>
                <Alert variant={this.state.browserValidation}>
                <ResultIcon result={this.state.browserValidation} /><small>{this.state.browser}</small>
                </Alert>
            </div>
        </div>
      }
    
}
export default DetectSystem;