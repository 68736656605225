import React from 'react';


class CameraTest extends React.Component {


    componentDidMount() {

        document.querySelector('#meetingTest').addEventListener('click', function(){
            if (!document.querySelector('iframe')) {
                const loader = document.createElement("div");
                loader.setAttribute('class', 'd-block spinner-border text-primary');
                loader.setAttribute("id", "loader");
                document.getElementById('meetingTest').parentNode.appendChild(loader);
                this.innerText = "Stop Testing";
                let endpoint = 'https://thelearninghub.empowerededu.org/ee_whereby/test.json';
                fetch(endpoint)
                .then((response) => {
                    if (response.ok) {
                      return response.json();
                    } else {
                      throw new Error('Something went wrong');
                    }})
                .then(data => {
                        document.getElementById('loader').remove();
                        const whereByIframe = document.createElement("iframe");
                        whereByIframe.setAttribute("src", data.room);
                        whereByIframe.setAttribute("id", "whereByIframe");
                        whereByIframe.setAttribute("allow", "camera; microphone; fullscreen; speaker; display-capture");
                        document.getElementById('meetingTest').parentNode.appendChild(whereByIframe);
                        setTimeout(() => {
                            this.innerText = "Test Meeting";
                            document.getElementById('whereByIframe').remove();
                        }, 300000);
                }).catch(error => {
                    this.innerText = "Test Meeting";
                    loader.setAttribute('class', 'd-block alert-danger p-2 my-2');
                    loader.innerText = error;
                });

            } else {
                this.innerText = "Test Meeting";
                document.getElementById('whereByIframe').remove();
            }
         });
        }
  
    render() {
        return <div className='p-3 text-start'>
        <span className="d-flex justify-content-between"><h6 className='fw-bold'>Meeting Test</h6><a className="text-dark" href='/sound-check'>Troubleshoot</a></span>
        <small><p className="">See if your system is ready to join meetings</p></small>
        <button className="btn btn-primary mb-2 me-2" id="meetingTest" type="button">Test Meeting</button>
       </div>
      }
    
}
export default CameraTest;