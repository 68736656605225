import React from 'react';

export default function SoundCheckTroubleshooting() {
  return <div className="w-100 text-start">
          <h1 className='mt-5 mb-3'>Sound Check Troubleshooting</h1>
          <div className="article-body">
              <h2>I Failed the Microphone System Check</h2>
              <p><span className="wysiwyg-font-size-large">If you fail the audio input microphone test, you may have issues with people not being about to hear you in the webinar room.</span></p>
            <ul>
            <li>If you are using an external headset or external speakers, try unplugging them.</li>
            <li>Is your microphone muted?</li>
            <li>Does your microphone work? <strong><a href="https://www.onlinemictest.com/" target="_blank" rel="noreferrer">Please use this independent online test.</a></strong></li>
            <li>Try<strong>restarting your browser</strong>.</li>
            <li>Try<strong>restarting your computer</strong>.</li>
            </ul>
            <p><strong>If nothing above works, please try troubleshooting by clicking the links below:</strong></p>
            <p><a href="https://support.microsoft.com/en-us/help/4027981/windows-how-to-set-up-and-test-microphones-in-windows-10" target="_blank" rel="noreferrer"><span className="wysiwyg-font-size-x-large">Click here for Windows.</span></a></p>
            <p><a href="https://support.apple.com/kb/PH25417?locale=en_US" target="_blank" rel="noreferrer"><span className="wysiwyg-font-size-x-large">Click here for Mac.</span></a></p>
            <p><span className="wysiwyg-font-size-x-large"><a href="https://www.onlinemictest.com/" target="_blank" rel="noreferrer">Independent Microphone Test.</a></span></p>
            <p><a href="https://test.webrtc.org/" target="_blank" rel="noreferrer"><span className="wysiwyg-font-size-x-large">System Test To Check for Compatibility Issues.</span></a></p>
            <p></p></div>
            <div className="article-body">
                <h2>I Failed the Audio Output System Test</h2>
                <p><span className="wysiwyg-font-size-large">If you fail the audio output test, you may have issues hearing anyone in the room.</span></p>
            <ul>
            <li>If you are using headphones or external speakers, try unplugging them. </li>
            <li>Is your device muted? Do your speakers/head phones work? <strong><a href="https://www.onlinemictest.com/sound-test/" target="_blank" rel="noreferrer">Please test your speakers.</a></strong></li>
            <li>Try <strong>restarting your browser</strong>.</li>
            <li>Try <strong>restarting your computer</strong>.</li>
            </ul>
            <p><strong>If nothing above works, please try troubleshooting by clicking the links below:</strong></p>
            <p><a href="https://support.microsoft.com/en-us/help/4026994/windows-fix-sound-problems" target="_blank" rel="noreferrer"><span className="wysiwyg-font-size-x-large">Click here for Windows.</span></a></p>
            <p><span className="wysiwyg-font-size-x-large"><a href="https://support.apple.com/en-us/HT203186" target="_blank" rel="noreferrer">Click here for Mac.</a></span></p></div>
        </div>;
}