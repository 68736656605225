import React from 'react';

export default function OperatingSystem() {
  return <div className="w-100 text-start">
          <h1 className='my-5'>Operating System</h1>
          <div className="article-body">
            <p>It's always best to update your operating system if possible. But here are our requirements:</p>
            <p><strong>Windows XP, Vista, 7* or later, Mac OS X 10.6 or later, or Linux: RHEL 5.6 or later, openSUSE 11.3 or later, or Ubuntu 10.04 or later.</strong></p>
            <p>Don’t know what system you’re using? No worries, just <strong><a href="http://supportdetails.com/" target="_blank" rel="noreferrer">click here for your system details</a></strong>. Please update your operating system when possible.Just <strong><a href="http://lmgtfy.com/?q=How+do+I+update+my+operating+system%3F+" target="_blank" rel="noreferrer">do a search</a></strong> to learn how to update your specific OS.</p>
            <p>Here is our <strong><a href="https://check.empowerededu.org/" target="_blank" rel="noreferrer">system check.</a></strong>This test will tell you if you're ok or need some updating.</p>
            <p><strong><em><a href="https://support.microsoft.com/en-us/help/4057281/windows-7-support-ended-on-january-14-2020" target="_blank" rel="noreferrer">*Windows 7 is no longer being updated by Microsoft.</a>It's best to update your operating system to the newest version if possible. If you are going to be using a Windows 7 device or expect other presenters will be, please switch your webinar's codec to VP8 within the edit section of your webinar.</em></strong></p>
          </div>
        </div>;
}