import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

//Pages
import SystemCheck from './components/SystemCheck/SystemCheck';
import NoMatch from './components/NoMatch/NoMatch';

//Articles
import IncreaseSpeed from './components/articles/IncreaseSpeed/IncreaseSpeed';
import OperatingSystem from './components/articles/OperatingSystem/OperatingSystem';
import InternetBrowser from './components/articles/InternetBrowser/InternetBrowser';
import SoundCheckTroubleshooting from './components/articles/SoundCheckTroubleshooting/SoundCheckTroubleshooting';
import WebcamCheckTroubleshooting from './components/articles/WebcamCheckTroubleshooting/WebcamCheckTroubleshooting';

function App() {
  return (
    <div>
      <Navbar className="w-100" bg="dark" expand="lg" variant="dark" fixed="top">
        <Container>
          <Navbar.Brand href="/">
          <img
        src="images/ee-white.png"
        height="50"
        className="d-inline-block align-top"
        alt="Empowered Education"
      /></Navbar.Brand>
          <Navbar.Toggle className="ma" aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown title="Troubleshooting" id="basic-nav-dropdown">
                <NavDropdown.Item href="/speed-increase">How to Increase Speed</NavDropdown.Item>
                <NavDropdown.Item href="/operating-system">Operating System</NavDropdown.Item>
                <NavDropdown.Item href="/internet-browser">Internet Browser Information</NavDropdown.Item>
                <NavDropdown.Item href="/sound-check">Sound Check Troubleshooting</NavDropdown.Item>
                <NavDropdown.Item href="/webcam-check">Webcam Check Troubleshooting</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    <div className='min-vh-50 container d-flex flex-column align-items-center mt-5'>
      <BrowserRouter>
        <Routes>
        <Route path='/' element={<SystemCheck/>} />
        <Route path='/test' element={<SystemCheck/>} />
        <Route path='/speed-increase' element={<IncreaseSpeed/>} />
        <Route path='/operating-system' element={<OperatingSystem/>} />
        <Route path='/internet-browser' element={<InternetBrowser/>} />
        <Route path='/sound-check' element={<SoundCheckTroubleshooting/>} />
        <Route path='/webcam-check' element={<WebcamCheckTroubleshooting/>} />
        <Route path='*' element={<NoMatch/>} /> 
        </Routes>
      </BrowserRouter>
      
    </div>
    </div>
  );
}

export default App;
