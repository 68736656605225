import React from 'react';

export default function WebcamCheckTroubleshooting() {
  return <div className="w-100 text-start">
          <h1 className='mt-5 mb-3'>Wenbcam Check Troubleshooting</h1>
          <div className="article-body"><p><span className="wysiwyg-font-size-large">If you fail the webcam test, people will not be able to see you during the webinar. Take the following steps to ensure you can use your webcam on The Learning Hub.</span></p>
            <ul>
            <li>If you are using an external camera, try unplugging it.</li>
            <li>If you are using a camera that is not a webcam, <strong><a href="/hc/en-us/articles/210379986-Difficulties-with-External-Camera-Headset-or-Microphone" target="_blank" rel="noreferrer">you may need to install additional software.</a></strong></li>
            <li>Does your camera work? <strong><a href="https://appr.tc/" target="_blank" rel="noreferrer">Please check here by clicking join.</a></strong></li>
            <li>Try<strong> restarting your browser</strong>.</li>
            <li>Try<strong> restarting your computer</strong>.</li>
            </ul>
            <p><strong>If nothing above works, please try troubleshooting by clicking the links below:</strong></p>
            <p><a href="https://support.microsoft.com/en-us/help/13753/windows-10-can-not-find-or-start-the-camera" target="_blank" rel="noreferrer"><span className="wysiwyg-font-size-x-large">Click here for Windows.</span></a></p>
            <p><a href="https://support.apple.com/en-us/HT203585" target="_blank" rel="noreferrer"><span className="wysiwyg-font-size-x-large">Click here for Mac.</span></a></p>
            <p><span className="wysiwyg-font-size-x-large"><a href="https://www.onlinemictest.com/webcam-test/" target="_blank" rel="noreferrer">Independent Webcam Test.</a></span></p>
            <p><a href="https://test.webrtc.org/" target="_blank" rel="noreferrer"><span className="wysiwyg-font-size-x-large">System Test To Check for Compatibility Issues.</span></a></p>
            </div>
        </div>;
}