import React from 'react';

export default function IncreaseSpeed() {
  return <div className="w-100 text-start">
          <h1 className='mt-5 mb-3'>How to Increase Your Connection Speed and Prevent Packet Loss</h1>
          <div className="article-body"><p>Experiencing connection issues during your The Learning Hub session? Your internet speed or packet loss might be the cause.</p>
          <p>In our experience, many technical issues on The Learning Hub are caused by slower or unstable internet (network) connections. So before your next webinar, please review the following steps to improve your Internet speed—and your overall experience on The Learning Hub.</p>
          <ul>
          <li><strong>Restart your browser, computer or router</strong>. If you are getting a <strong>robotic sound</strong>, restarting your computer might be necessary.</li>
          <li><strong>Update your operating system or browser</strong>. Not updating regularly can slow your connection speed. Please review The Learning Hub's<strong> <a href="/hc/en-us/categories/200248279-System-requirements" target="_blank" rel="noreferrer">System Requirements.</a></strong></li>
          <li>If possible, <strong>plug directly into your internet router</strong>with an ethernet connection. <strong><a href="https://en.wikipedia.org/wiki/Packet_loss" target="_blank"  rel="noreferrer">Packet loss</a></strong>is inherent in a wireless connection and directly affects your audio and video quality. If plugging into your router isn't an option, move closer to the router or extender. To prevent interference, keep your device and router/extender at least 2 feet apart.</li>
          <li><strong>Close all your browser tabs, windows, and other apps</strong> running in the background. Similarly, manually quit Loom, Skype, MS Teams, or any other video sharing apps that can "hog" your device's camera and microphone capabilities.</li>
          <li><strong>Switch</strong> <strong>Wi-Fi networks</strong> if possible (especially if you are on a public or shared network) or ask others to log off.</li>
          <li><strong>Reduce network activity</strong>. Stop streaming music or videos on the same network when using The Learning Hub. Ask others on your network to do the same.</li>
          <li><strong>Disableor remove any browser extensions</strong> that might be blocking or slowing down your ability to connect.</li>
          <li><strong>Disconnect any VPNs if possible</strong>. These may also be browser extensions.</li>
          <li><strong>Switch browsers. </strong>If you are a presenter, switch to Chrome or Firefox, our recommended browsers for presenters<strong>. </strong>And although attendees can access The Learning Hub from any browser, users on Chrome or Firefox tend to experience less connection issues and a more seamless experience.</li>
          <li>If you're using Chrome, <strong>try turning off Hardware Acceleration</strong>.</li>
          <li><strong>Switch devices</strong>. If you are using a mobile device, switch to a computer or laptop if possible.</li>
          <li><strong>Change router settings</strong>. If you have a dual-band router, use the 5 Ghz Wi-Fi band instead of the 2.4 Ghz band. Also, make sure the encryption method on your router is AES or "Mixed." You may need to check your Wi-Fi router's instructions or contact the router's manufacturer.</li>
          <li><strong>Replace older router</strong>. Replacing older routers with a newer model as most connection issues are caused by outdated routers.</li>
          <li><strong>Replace older cables</strong>connected to your computer, router, or modem.</li>
          <li><strong>Disable wireless devices</strong> like speakers, microphones or headphones. These can cause interference.</li>
          <li>If you're the webinar host, <a  rel="noreferrer" href="/hc/en-us/articles/210058366-How-do-I-edit-my-server-location-" target="_blank"><strong>change the server location of your webinar</strong></a>. Choose a server location that's closest to the majority of your presenters.</li>
          <li><a  rel="noreferrer" href="https://www.computerhope.com/issues/ch002001.htm" target="_blank"><strong>Check your system's activity monitor</strong></a>, and <strong><a href="https://techstacker.com/how-to-stop-chrome-from-using-all-your-cpu/Lfi7yNPXnTtxAJSzG/" target="_blank"  rel="noreferrer">close an unnecessary or unfamiliar applications</a></strong> that are using a lot of CPU (100% or higher) or memory power. You might need to disconnect external devices like monitors that can use more CPU when connected to your computer.</li>
          <li>You may need to turn off your webcam or screen share and <strong>switch to Microphone Audio Only or Dial-in.</strong>Audio only uses much less bandwidth.</li>
          <li>Consider these other <strong><a  rel="noreferrer" href="https://www.lifewire.com/how-to-fix-packet-loss-4176342" target="_blank">solutions for connection issues that might cause packet loss</a></strong>.</li>
          <li>If you are using a DSL modem with a slow uplink, a 3G wireless modem, a hotspot, 3 or 4G mobile connection, a network with some type of <a href="/hc/en-us/articles/206927906-If-you-re-behind-a-network-Firewall-or-Proxy-allow-all-traffic-over-port-443" target="_blank"  rel="noreferrer">proxies/firewalls</a>, or just a slower speed connection, your webinar experience, and quality may suffer, including <strong>loss of connection, robotic audio, or video and screen sharing not loading or freezing.</strong></li>
          <li>Still experiencing connection or streaming issues? Contact your Internet provider for more help.</li>
          </ul>
          </div>
         </div>;
}