import React from 'react';

class SoundCheck extends React.Component {
    componentDidMount() {
        function handleSuccess(stream) {
            if (window.stream) {
              window.stream.getAudioTracks().forEach(track => track.stop());
              window.stream = null;
            } else {
              const audio = document.createElement('audio');
              audio.controls = true;
              audio.autoplay = true;
              window.stream = stream;
              audio.srcObject = stream;
          
              stream.oninactive = function() {
                console.log('Stream ended');
              };
            }
          }
          
          function handleError(e){
            console.log("ruin", e.message);
          }
          
          document.querySelector("#micTest").addEventListener('click', function(){
            if ( window.stream ) {
              this.innerText = "Test Microphone";
            } else {
              this.innerText = "Stop Test";
            }
            if (navigator.mediaDevices) {
              const constraints = window.constraints = {
                audio: true, 
                video: false
              }
              navigator.mediaDevices.getUserMedia(constraints).then(handleSuccess).catch(handleError)
            }
          });
          document.querySelector('#soundTest').addEventListener('click', function(){
            if (!document.querySelector('audio')) {
            const audio = document.createElement('audio');
            audio.controls = true;
            audio.autoplay = true;
            audio.src = '/sound-test.mp3';
            document.getElementById('soundTest').parentNode.appendChild(audio);
            let descriptionTag = document.createElement("p");
            let description = document.createTextNode("You should hear running water");
            descriptionTag.appendChild(description);
            document.getElementById('soundTest').parentNode.appendChild(descriptionTag);
            }
        });

    }
  
  
    render() {
        return <div className='p-3 border-bottom text-start'>
         <span className="d-flex justify-content-between"><h6 className='fw-bold'>Sound Check</h6><a className="text-dark" href='/sound-check'>Troubleshoot</a></span>
        <small><p className="">See if your microphone and headphones are setup properly</p>
                      <p> For the Microphone test, a prompt will appear asking for permission to access your select output device </p>
                      <p>If you are not using headphones, it'll have a loop feedback</p></small>
                      <button className="btn btn-primary mb-2 me-2" id="micTest" type="button">Test Microphone</button>
                      <button className="btn btn-secondary mb-2 me-2" id="soundTest" type="button">Test Headphone/Speakers</button> 
        </div>
        
      }
    
}
export default SoundCheck;